.lottie_file {
    position: fixed;
    top: 0;
    left: 0;
}

@media (max-width: 500px) {
    .lottie_file {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vh;
    }
}

.ChangePassContainer {
    position: absolute;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px) {
    .form_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        width: 75vw;
        height: 50vh;
        background: rgba(255, 255, 255, 0.279);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    .textfield {
        background-color: white;
        border-radius: 5px;
        width: 65vw;
    }
}

@media (min-width: 500px) {
    .form_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        width: 50vw;
        height: 50vh;
        background: rgba(255, 255, 255, 0.279);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    .textfield {
        background-color: white;
        border-radius: 5px;
        width: 40vw;
    }
}

.form_title {
    color: white;
    margin-top: -50px;
}



.show_pass {
    background-color: transparent;
    border: none;
    color: gray;
    height: 30px;
    width: 30px;
    margin-left: -5px;
}
.lock_icon {
    height: 30px;
    width: 20px;
    margin-left: -2px;
}