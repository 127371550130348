
#video_background{
    object-fit: cover;
    width: 35vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index:-2;
}

#video_background_mobile{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index:-10;
    filter: blur(2px);
  -webkit-filter: blur(2px);
    top: 0;
    left: 0;
    
}